import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'va-badge-power',
  template: `
  <span
    class="badge-power"
    [ngStyle]="{
        'background-color': power ? (customColor?.PositiveColor || 'green') : (customColor?.NegativeColor || 'grey')
      }"
  >
  </span>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgePowerComponent implements OnInit {
  @Input() power: boolean;
  @Input() customColor: {
    PositiveColor: string,
    NegativeColor: string,
  };

  constructor() {}

  ngOnInit(): void {
    console.log();
  }
}
